import React from "react";
import { TicketPurchaseUI } from "features";

const TicketPurchase = () => {
	return (
		<div>
			<TicketPurchaseUI />
		</div>
	);
};

export { TicketPurchase };
