import React from "react";
import { LandingPageUI } from "features";

const LandingPage = () => {
	return (
		<div>
			<LandingPageUI />
		</div>
	);
};

export { LandingPage };
