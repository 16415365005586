import React from "react";
import { GalleryUI } from "features";

const Gallery = () => {
	return (
		<div>
			<GalleryUI />
		</div>
	);
};

export { Gallery };
