import React from 'react'
import { ContactUI } from 'features'

const Contact = () => {
  return (
    <div>
      <ContactUI />
    </div>
  )
}

export {Contact}
