import {  useState } from "react";

import styles from "./styles.module.scss";
import {
  Button,
  Confirmation,
  FormUI,
  Input,
  Select,
  TicketProps,
  TicketSale,
  TopTitle,
} from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "router";
// import { SCRIPT_API_URL } from "config";
import axios from "axios";

const TicketCheckoutUI = () => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    category: "",
    ticket: "",
  });

  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    category: "",
    ticket: "",
  });

  function formatDateString(dateString: string) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const handleSubmit = () => {
    const keys = Object.keys(userInfo);

    if (keys.some((key) => userInfo[key] === "")) {
      setErrors({
        firstName: userInfo.firstName === "" ? "Required" : "",
        lastName: userInfo.lastName === "" ? "Required" : "",
        email: userInfo.email === "" ? "Required" : "",
        number: userInfo.number === "" ? "Required" : "",
        category: userInfo.category === "" ? "Required" : "",
        ticket: userInfo.ticket === "" ? "Required" : "",
      });
    } else {
      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        category: "",
        ticket: "",
      });
      onSubmit();
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const time = formatDateString(new Date().toISOString());
    const event = `${state.day} - ${state.month} ${state.date}, ${state.time}, ${state.name}, ${state.venue}`;
    const formData = Object.entries({
      ...userInfo,
      event,
      time,
    }).reduce((acc, [key, value]) => {
      acc.append(key, value);
      return acc;
    }, new FormData());
    formData.append("form_id", "ticket");
    formData.append("time", time);
    formData.append("Event", event);
    formData.append("Category", userInfo.category);
    formData.append("Ticket", userInfo.ticket);
    formData.append("First name", userInfo.firstName);
    formData.append("Last name", userInfo.lastName);
    formData.append("Email", userInfo.email);
    formData.append("Phone number", userInfo.number);
    
    try {
      const response = await axios.post("https://script.google.com/macros/s/AKfycby117qSYbrz51Opa8J-ec8vdDnvVeWfC5oUzBN47JoESghABG-5RqnJ8E3wCIt15yo4XA/exec", formData);
      console.log(response);
      setSuccess(true);
      setUserInfo({
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        category: "",
        ticket: "",
      });
      setTimeout(() => {
        setSuccess(undefined);
      }, 10000);
    } catch (error) {
      console.error(error);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.contactDiv}>
      {success === undefined ? (
        <>
          <div className={styles.top}>
            <TopTitle text={"YOU ARE ALMOST THERE"} position={true} />
          </div>
          <div className={styles.checkout}>
            <TicketSale
              hideButton
              {...(state as TicketProps)}
              className={styles.info}
            />
            <FormUI className={styles.formInfo}>
              <Select
                onChange={(value) =>
                  setUserInfo({ ...userInfo, category: value })
                }
                value={userInfo.category}
                error={errors.category}
                disabled={loading}
                label="Category"
                placeholder="Please select"
                options={state.ticketOptions}
                parentClassName={styles.category}
              />
              <Select
                onChange={(value) =>
                  setUserInfo({ ...userInfo, ticket: value })
                }
                value={userInfo.ticket}
                error={errors.ticket}
                disabled={loading}
                label="Amount"
                placeholder="Please select"
                options={[
                  "1 Ticket",
                  "2 Tickets",
                  "3 Tickets",
                  "4 Tickets",
                  "5 Tickets",
                  "5+ Tickets",
                  "10+ Tickets",
                ]}
                parentClassName={styles.ticket}
              />
              <Input
                type={"text"}
                label={"First name"}
                placeholder="First name"
                size="half"
                onchange={(value) =>
                  setUserInfo({ ...userInfo, firstName: value })
                }
                error={errors.firstName}
                disabled={loading}
              />
              <Input
                type={"text"}
                label={"Last name"}
                placeholder="Last name"
                size="half"
                onchange={(value) =>
                  setUserInfo({ ...userInfo, lastName: value })
                }
                error={errors.lastName}
                disabled={loading}
              />
              <Input
                type={"email"}
                label={"Email"}
                placeholder="you@email.com"
                size="full"
                onchange={(value) => setUserInfo({ ...userInfo, email: value })}
                error={errors.email}
                disabled={loading}
              />
              <Input
                type={"tel"}
                label={"Phone number"}
                placeholder="+1 (555) 000-0000"
                size="full"
                onchange={(value) =>
                  setUserInfo({ ...userInfo, number: value })
                }
                error={errors.number}
                disabled={loading}
              />

              <Button
                text={loading ? "LOADING..." : "SUBMIT"}
                available={"open"}
                onClick={handleSubmit}
                className={styles.submitBtn}
              />
            </FormUI>
          </div>
        </>
      ) : (
        <Confirmation
          isSuccess={success}
          buttonText={success ? "RETURN TO TICKETS" : "CLOSE"}
          text="Our representatives will contact you shortly to discuss payment methods and delivery options."
          handleBack={() =>
            success ? navigate(Routes.landing) : navigate(Routes.tickets)
          }
        />
      )}
    </div>
  );
};

export { TicketCheckoutUI };
